const CONSTANTS = require("../../../lib/index");

function DialogOptions(title, body, buttonText) {
	this.title = title;
	this.body = body;
	this.buttonText = buttonText;
}

function openDialog(dialogType, callback, options) {
	var canBuyCredits = false;

	if (window.accountConfigurationModel?.privileges) {
		canBuyCredits = Boolean(
			window.accountConfigurationModel.privileges.hasCreditPurchaseAccess
		);
	}

	switch (dialogType) {
		case CONSTANTS.DIALOG.ACCOUNT_SETTINGS:
			openAccountSettingsDialog(callback);
			break;
		case CONSTANTS.DIALOG.BATCH:
			openBatchDialog(callback);
			break;
		case CONSTANTS.DIALOG.ACCOUNT_SETTINGS_VIEW_ONLY:
			var title = "URL Link: View Only Mode";
			var body =
				"Account Settings are unavailable on URL Links due to company settings.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.INSUFFICIENT_FUNDS_GENERAL:
			var callback = canBuyCredits
				? function () {
						openDialog(CONSTANTS.DIALOG.ACCOUNT_SETTINGS);
				  }
				: null;
			var title = "Insufficient Funds";
			var body = canBuyCredits
				? "Purchase more credits to continue use"
				: "Contact the account owner to purchase more";
			var buttonText = canBuyCredits
				? "Open Account Settings to Make a Purchase"
				: "Got It!";

			openGeneralDialog(callback, new DialogOptions(title, body, buttonText));
			break;
		case CONSTANTS.DIALOG.INSUFFICIENT_FUNDS_BATCH:
			var callback = canBuyCredits
				? function () {
						openDialog(CONSTANTS.DIALOG.ACCOUNT_SETTINGS);
				  }
				: null;
			var title = "Insufficient Funds";
			var body = canBuyCredits
				? "You must have enough credits to run the entire batch request"
				: "Contact the account owner to purchase more";
			var buttonText = canBuyCredits
				? "Open Account Settings to Make a Purchase"
				: "Got It!";

			openGeneralDialog(callback, new DialogOptions(title, body, buttonText));
			break;
		case CONSTANTS.DIALOG.INSUFFICIENT_FUNDS_PDF:
			var callback = canBuyCredits
				? function () {
						openDialog(CONSTANTS.DIALOG.ACCOUNT_SETTINGS);
				  }
				: null;
			var title = "Insufficient Funds";
			var body = canBuyCredits
				? "You do not have enough credits to download a PDF. Please purchase more to continue"
				: "Contact the account owner to purchase more";
			var buttonText = canBuyCredits
				? "Open Account Settings to Make a Purchase"
				: "Got It!";

			openGeneralDialog(callback, new DialogOptions(title, body, buttonText));
			break;
		case CONSTANTS.DIALOG.TERMS_OF_SERVICE:
			var title = "Terms and Conditions";
			var body =
				"You're almost finished! Please accept the Terms and Conditions to continue signup";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.WAIT_FOR_ACTIVATION:
			var callback = function () {
				window.location = "/";
			};
			var title = "Await Account Activation";
			var body =
				"A Maprisk staff member will email you once your account has been activated";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.LOCKED_UNTIL_PURCHASE:
			var callback = canBuyCredits
				? function () {
						openDialog(CONSTANTS.DIALOG.ACCOUNT_SETTINGS);
				  }
				: null;
			var title = "Purchase Credits to Unlock";
			var body = canBuyCredits
				? "Locked reports will be made available after your first credit purchase"
				: "Contact the account owner to purchase credits and unlock reports";
			var buttonText = canBuyCredits
				? "Go to Account Settings to Purchase Credits"
				: "Got It!";

			openGeneralDialog(callback, new DialogOptions(title, body, buttonText));
			break;
		case CONSTANTS.DIALOG.ACCOUNT_ALREADY_EXISTS:
			var callback = function () {
				$("#txtCreateAccountEmailAddress").val("").focus();
			};
			var title = "Unable to Create Account";
			var body =
				options && options.body
					? options.body
					: "There was an error creating your account.  Please contact support@maprisk.com for assistance.";

			grecaptcha.reset();
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.ACCOUNT_CREATE_ERROR_GENERIC:
			var callback = function () {
				window.location = "/";
			};
			var title = "Unable to Create Account";
			var body =
				options && options.body
					? options.body
					: "There was an error creating your account. Please contact support@maprisk.com for assistance.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.CAPTCHA_VALIDATION_FAILURE:
			var title = "Please Complete reCAPTCHA";
			var body =
				options && options.body
					? options.body
					: "Please verify you are not a robot by filling out the reCAPTCHA";

			grecaptcha.reset();
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.INVALID_CREDENTIALS:
			var title = "Login Error";
			var body =
				"There was a problem logging you in.  Please try again or contact support@maprisk.com for assistance.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.RATE_LIMITED_LOGIN:
			var title = "Login Error";
			var body =
				"<p>You have exceeded the number of login attempts.  Please wait 5 minutes before trying again, and <a href='/account/forgot-password'>Reset Your Password</a> if necessary.</p><p>If further assistance is required, please e-mail <a href='mailto:support@maprisk.com'>support@maprisk.com</a>.</p>";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.RATE_LIMITED_GEOCODE:
			var title = "Geocode Error";
			var body =
				"<p>You have exceeded your allotted number of geocode request attempts.  Please wait a little while and try again.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.RATE_LIMITED_REPORT:
			var title = "Report Request Error";
			var body =
				"<p>You have exceeded your allotted number of report request attempts.  Please wait a little while and try again.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.BATCH_DISABLED:
			var title = "Please Contact Sales";
			var body =
				"The Maprisk Batch Tool is not enabled for this account. Contact sales@maprisk.com with any questions.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.BATCH_DISABLED_VIEW_ONLY:
			var title = "URL Link: View Only Mode";
			var body =
				"The Maprisk Batch Tool is not available on URL Links due to company settings.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.CHECK_INBOX_FOR_PW_RESET_LINK:
			var title = "We Sent You An Email";
			var body =
				"Check your inbox for a password reset link. Click through to reset your password";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.PASSWORD_RESET_REQUEST_ERROR:
			var title = "Password Reset Request Error";
			var body =
				"We could not fulfill your password reset request. Contact support@maprisk.com for assistance.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.ACCOUNT_NOT_ACTIVATED:
			var title = "Account Not Activated";
			var body =
				"Your account has not been activated yet. Please wait for an activation confirmation email from MapRisk.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.PASSWORD_FIELDS_MUST_MATCH:
			var title = "Passwords Must Match";
			var body = "Please make sure that the passwords you entered match";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.PASSWORD_SECURITY_ERROR:
			var title = "Reset Password Error";
			var body =
				"The supplied password does not meet Maprisk's security requirements. Please enter a new password and try again.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.SUBMIT_NEW_PASSWORD_ERROR:
			var title = "Reset Password Error";
			var body =
				"Your password could not be reset due to an error. Try again, or contact support@maprisk.com for assistance";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.PASSWORD_RESET_SUCCESS:
			var title = "Success";
			var body = "Your password has been reset";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.PLEASE_ENTER_AN_ADDRESS:
			var title = "No Address Provided";
			var body = "Please enter an address prior to running reports";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.NOT_AUTHORIZED_TO_IMPERSONATE:
			var title = "Not Authorized";
			var body = "You do not have authorization to impersonate.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.GEOCODE_ERROR:
			var title = "Geocode Error";
			var body = "Unable to geocode, please check address and try again.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.GEOCODE_SERVER_ERROR:
			var title = "Geocode Error";
			var body = "The address provided could not be geocoded";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.NO_REPORT_DATA_UNABLE_TO_DOWNLOAD_PDF:
			var title = "Report Data Error";
			var body = "No report data, unable to download PDF";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.APPEND_REPORT_RESULT_ERROR:
			var title = "Report Data Error";
			var body =
				"There was an issue getting your report data. Please try again.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.BATCH_DELETE_ERROR:
			var title = "Batch Delete Error";
			var body = "There was a problem deleting the batch. Please try again.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.GEOCODE_TRANSACTION_ERROR:
			var title = "Gecode Transaction Error";
			var body = "Could not update geocode transaction. Please try again.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.DRAG_MARKER_ERROR:
			var title = "Drag Marker Error";
			var body =
				"Marker may only be moved within the displayed boundary. Please try again.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.STREET_VIEW_ERROR:
			var title = "Street View Error";
			var body = "Sorry! Street View is not available for that location.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.ACCOUNT_NOT_FOUND:
			var title = "We Sent You An Email";
			var body =
				"Check your inbox for a password reset link. Click through to reset your password";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.CREDIT_PURCHASE_ERROR:
			var title = "Credit Purchase Error";
			var body =
				"There was a problem with the order. Please contact support@maprisk.com for assistance.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		case CONSTANTS.DIALOG.BATCH_ERROR:
			var title = "Batch Error";
			var body =
				"There was a problem performing your batch task. Please try again.";
			break;
		case CONSTANTS.DIALOG.INVALID_URL_LINK:
			var title = "Invalid URL";
			var body =
				"The URL you have used no longer works as it has expired. Unable to load transaction. Please try again.";
			if (window.dialogInfo.isOpen) {
				var callback = function () {
					window.location = "/";
				};
				openGeneralDialog(callback, new DialogOptions(title, body));
				break;
			} else {
				openGeneralDialog(callback, new DialogOptions(title, body));
				break;
			}
		case CONSTANTS.DIALOG.INVALID_PORTFOLIO_LINK:
			var title = "Invalid Portfolio Link";
			var body =
				"The URL you have used no longer works as it has expired or does not reference a portfolio report.";
			if (window.dialogInfo.isOpen) {
				var callback = function () {
					window.location = "/";
				};
				openGeneralDialog(callback, new DialogOptions(title, body));
				break;
			} else {
				openGeneralDialog(callback, new DialogOptions(title, body));
				break;
			}
		case CONSTANTS.DIALOG.IE_GOOGLE_MAPS_DEPRECATION:
			var title = "Internet Explorer 11 Deprecation";
			var body =
				"Dear Maprisk Customer:<br>Google has recently announced the discontinuation of Google Maps support for Internet Explorer 11, as Microsoft will officially end IE11 support as of June 2022.  Due to this decision, Google Maps will no longer load in Internet Explorer 11 beginning August 2022.<br><br>As the Maprisk platform depends on Google Maps to provide our users mapping and data visualization services, our web application will also no longer be able to support IE11.<br><br>To continue using Maprisk's services in the browser, we strongly recommend switching to a modern, widely-supported browser such as <a href='https://www.microsoft.com/en-us/edge' target='_blank' rel='noopener'>Microsoft Edge</a>, <a href='https://www.mozilla.org/en-US/firefox/new/' target='_blank' rel='noopener'>Mozilla Firefox</a>, or <a href='https://www.google.com/chrome/' target='_blank' rel='noopener'>Google Chrome</a>.<br><br>Please contact your IT department or <a href='mailto:support@maprisk.com'>Maprisk support</a> with any questions regarding this browser upgrade.";
			openGeneralDialog(callback, new DialogOptions(title, body));
			break;
		default:
			var title = "Error";
			var body =
				"An error has occurred, please try again or contact <a href='mailto:support@maprisk.com'>Maprisk support</a> for assistance.";
			if (window.dialogInfo.isOpen) {
				var callback = function () {
					window.location = "/";
				};
				openGeneralDialog(callback, new DialogOptions(title, body));
				break;
			} else {
				openGeneralDialog(callback, new DialogOptions(title, body));
				break;
			}
	}
}

//  ACCOUNT DIALOG

function openAccountSettingsDialog(callback) {
	if (callback && typeof callback === "function") {
		window.dialogCloseCallback = callback;
	}

	$("#account-settings-dialog").addClass("active");

	$("#account-control").removeClass("disabled");
	$("#account-control").addClass("active");

	$("#account-settings")[0].style.display = "inline-block";
	$(".account-settings-dialog-backdrop")[0].style.display = "inline-block";
	$("#account-settings-dialog")[0].style.display = "inline-block";

	$("#account-settings-dialog .close-btn").on("click", function (e) {
		e.preventDefault();
		$("#account-settings-dialog .close-btn").off("click");
		dismissAccountSettingsDialog();
	});
	$(".account-settings-dialog-backdrop").on("click", function (e) {
		e.preventDefault();
		$(".account-settings-dialog-backdrop").off("click");
		dismissAccountSettingsDialog();
	});
}

function dismissAccountSettingsDialog() {
	$("#account-settings-dialog").removeClass("active");

	$("#account-control").removeClass("disabled");
	$("#account-control").addClass("active");

	$(".account-settings-dialog-backdrop")[0].style.display = "none";
	$("#account-settings-dialog")[0].style.display = "none";

	if (!window.dialogCloseCallback) {
		return;
	}

	window.dialogCloseCallback();
	window.dialogCloseCallback = null;
}

//  BATCH DIALOG

function openBatchDialog(infoContents, cb) {
	if (!window.mrState.batchEnabled) {
		return;
	}

	if (typeof cb === "function") {
		window.dialogCloseCallback = cb;
	}

	// close account settings if open
	dismissAccountSettingsDialog();

	// trigger batch history update
	$(document).trigger("customEvent.batch.batchDialogOpened");

	$("#batch-button")[0].classList.remove("disabled");
	$("#batch-button")[0].classList.add("active");
	$("#batch-wizard")[0].style.display = "block";
	$(".batch-dialog-backdrop")[0].style.display = "block";
	$("#batch-dialog")[0].style.display = "block";

	$("#batch-dialog .close-btn").click(function (e) {
		e.preventDefault();
		dismissBatchDialog();
	});
	$(".batch-dialog-backdrop").click(function () {
		dismissBatchDialog();
	});
}

function dismissBatchDialog() {
	$(document).trigger("customEvent.batch.batchDialogClosed");
	$(".batch-dialog-backdrop")[0].style.display = "none";
	$("#batch-dialog")[0].style.display = "none";

	$("#batch-button")[0].classList.add("disabled");
	$("#batch-button").removeClass("active");

	if (!window.dialogCloseCallback) {
		return;
	}

	window.dialogCloseCallback();
	window.dialogCloseCallback = null;
}

function openGeneralDialog(callback, options) {
	if (callback && typeof callback === "function") {
		window.dialogCloseCallback = callback;
	}

	$("#general-dialog .header .title p").html(options.title);
	$("#general-dialog .contents #dynamic-body #general-message p").html(
		options.body
	);
	$("#general-dialog")[0].style.display = "inline-block";
	$(".general-dialog-backdrop")[0].style.display = "inline-block";
	$("#dynamic-body")[0].style.display = "inline-block";

	if (options && options.buttonText) {
		$("#general-submit-button").html(options.buttonText);
	} else {
		$("#general-submit-button").html("Got It!");
	}

	if ($("#general-submit-button").length > 0) {
		$("#general-submit-button").focus();
	} else if ($("#general-dialog .close-btn").length > 0) {
		$("#general-dialog .close-btn").focus();
	}

	$("#general-dialog .close-btn").click(function (e) {
		e.preventDefault();
		dismissGeneralDialog(false);
	});
	$("#general-submit-button").click(function () {
		dismissGeneralDialog(true);
	});
	$(".general-dialog-backdrop").click(function () {
		dismissGeneralDialog(false);
	});
}

function dismissGeneralDialog(shouldCallback) {
	if (
		window.mrState.viewOnly.enabled !== true ||
		(window.mrState.viewOnly.enabled === true &&
			window.mrState.viewOnly.modifyAddressBar === true)
	) {
		window.addressSearch.enableSearchButton();
	}

	$("#general-dialog")[0].style.display = "none";
	$(".general-dialog-backdrop")[0].style.display = "none";
	$("#general-dialog .header .title p").html("");
	$("#general-dialog .contents .general-message p").html("");
	$("#general-submit-button").html("Got It!");

	if (!window.dialogCloseCallback || !shouldCallback) {
		return;
	}

	window.dialogCloseCallback();
	window.dialogCloseCallback = null;
}

module.exports = {
	DialogOptions,
	openDialog,
	openAccountSettingsDialog,
	dismissAccountSettingsDialog,
	openBatchDialog,
	dismissBatchDialog,
	openGeneralDialog,
	dismissGeneralDialog
};
